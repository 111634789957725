import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
// import { RayVuexPlugin } from 'vue-ray/vue2'
import app from './app'
import auth from './auth'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import model from './model'
import general from './general'

import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

const logrocketPlugin = createPlugin(LogRocket)

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
    model,
    general,
    // 'app-ecommerce': ecommerceStoreModule,
  },
  plugins: [logrocketPlugin],
  strict: process.env.DEV,
})
