import axios from '@/libs/axios'
import { initialAbility } from '@/libs/acl/config'
import ability from "@/libs/acl/ability"
const Sentry = require('@sentry/vue')

export default {
    namespaced: true,
    state: {
        logged: false,
        user: (() => {
            const userData = JSON.parse(localStorage.getItem('userData'))
            return userData ? userData : null
        })(),
        company: (() => {
            const company = JSON.parse(localStorage.getItem('currentCompany'))
            return company ? company : null
        })(),
    },
    getters: {
        currentSlug: state => {
            const { company } = state
            if (company.slug) return company.slug
            return false
        },
    },
    mutations: {
        SET_LOGGED(state, logged) {
            state.logged = logged
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_COMPANY(state, company) {
            if (company) {
                localStorage.setItem('currentCompany', JSON.stringify(company))
            } else {
                localStorage.removeItem('currentCompany')
            }
            state.company = company
        },
    },
    actions: {
        async loginCompany({ commit }, slug) {
            await axios.get('/sanctum/csrf-cookie')
            const { data } = await axios.get(`${process.env.VUE_APP_API_V}/tenants/customers-for-user/${slug}`)
            commit('SET_COMPANY', data)
        },
        async logoutCompany({ commit }) {
            // Remove userData from localStorage
            localStorage.removeItem('currentCompany')
            localStorage.removeItem('currentSlug')
            // Vaciar store.state.user
            commit('SET_COMPANY', null)
        },
        async login({ commit }, credentials) {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/login', credentials)
            commit('SET_LOGGED', true)
            commit('SET_USER', data)
        },
        async logout({ commit }) {
            const currentSlug = localStorage.getItem('currentSlug')
            await axios.post(`${process.env.VUE_APP_API_V}/${currentSlug}/logout`, {})
                .then(async res => {
                    // Remove userData from localStorage
                    localStorage.removeItem('userData')
                    // Remove sentry user
                    Sentry.configureScope(scope => scope.setUser(null))
                    // Remove Crisp Chat user
                    // window.$crisp.set('session:data', [])
                    // Vaciar store.state.user
                    commit('SET_USER', null)

                    // Reset ability
                    ability.update(initialAbility)
                    commit('SET_LOGGED', false)
                })
                .catch(error => {
                })
        },
    },
}
