import Vue from 'vue'
import router from '@/router'
import store from '@/store'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
    config => {
        if (process.env.VUE_APP_DEBUG) console.log(config)
      return config
    },
    error => Promise.reject(error.response),
)

axiosIns.interceptors.response.use(
    response => {
        if (process.env.VUE_APP_DEBUG) console.log([response.status, response])
      return response
    },
    error => {
      // const { config, response: { status } } = error
      const { config, response } = error
      const originalRequest = config
        if (process.env.VUE_APP_DEBUG) console.log([response.status, response, originalRequest])
      if ((response && response.status === 419) || (response && response.status === 401)) {
          // desloguarse por completo si se ha expirado la sesión contra API
        store.dispatch('auth/logout')
        router.push({ name: 'auth-login' })
        return originalRequest
      }
      return Promise.reject(error)
    },
)

Vue.prototype.$http = axiosIns

export default axiosIns
