// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchNotifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/alerts`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    readNotifications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/alerts/${queryParams.id}/read`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchSearch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/search/${queryParams.type}`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
  },
}
