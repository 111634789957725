import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        notifications: null,
    },
    getters: {
        getNotifications(state) {
            return state.notifications
        },
    },
    mutations: {
        setNotifications(state, data) {
            state.notifications = data
        },
        updateNotifications(state, data) {
            Object.entries(data).forEach(([key, val]) => { state.notifications[key] = val })
        },
        deleteNotifications(state, index) {
            state.notifications.splice(index, 1)
            // _.remove(state.notifications, n => (n.id === params.id))
        },
    },
    actions: {
        refreshState(ctx, queryParams) {
            return true
        },
    },
}
