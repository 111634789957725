// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMaterials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/materials/list`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    addMaterial(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/materials`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMaterialsForSelect() {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/materials/select`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteMaterial(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .delete(`${process.env.VUE_APP_API_V}/${currentSlug}/materials/${queryParams.id}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    updateMaterial(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/materials/${queryParams.id}`, formData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
