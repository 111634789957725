// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTenant(ctx, uuid) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/tenant-settings/${uuid}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    updateTenant(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/tenant-settings/update`, queryParams, formHeaders)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
