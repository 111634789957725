// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/clients/list`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClientProjects(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/clients/${id}/projects`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addClient(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/clients`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    updateClient(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/clients/${queryParams.id}`, formData, null)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
