import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  getCurrentSlug,
  getCurrentCompany,
  getHomeRouteForLoggedCompany,
} from '@/auth/utils'
import apps from './routes/apps'
import pages from './routes/pages'

Vue.use(VueRouter)

const currentSlug = getCurrentSlug()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL + currentSlug,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    ...pages,
    {
      path: '/:slug?/:param1?/:param2?/',
      name: 'auth-company',
      component: () => import('@/views/pages/authentication/LoginGeneral.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfCompanyIn: true,
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_DEBUG) console.log(['VUE ROUTES', to, from, next])

  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'home' }) // 'misc-not-authorized'
  }

  // Redirect if not company
  if (to.meta.redirectIfNotCompany) {
    const currentCompany = getCurrentCompany()
    if (!currentCompany) {
      next(getHomeRouteForLoggedCompany(currentCompany))
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  // Redirect if not company
  if (to.meta.redirectIfCompanyIn) {
    const currentCompany = getCurrentCompany()
    if (currentCompany) {
      next(getHomeRouteForLoggedCompany(currentCompany))
    }
  }

  const slugUrlExist = window.location.pathname.indexOf(currentSlug)
  if (currentSlug && slugUrlExist == -1) {
    // añadir Slug al path porque no lo lleva
    // return next({ name: to.name })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
