import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/tasks`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/tasks/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/tasks`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
      editTask(ctx, queryParams) {
          const formData = new FormData()
          // eslint-disable-next-line no-unused-expressions
          Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
          formData.append('_method', "PATCH")

          return new Promise((resolve, reject) => {
              const currentSlug = localStorage.getItem('currentSlug')
              // const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
              axios
                  .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/tasks/${queryParams.id}`, formData)
                  .then(response => resolve(response))
                  .catch(error => {
                      reject(error)
                  })
          })
      },
      deleteTask(ctx, queryParams) {
          return new Promise((resolve, reject) => {
              const currentSlug = localStorage.getItem('currentSlug')
              axios
                  .delete(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/tasks/${queryParams.id}`)
                  .then(response => resolve(response))
                  .catch(error => {
                      reject(error)
                  })
          })
      },
    addTaskUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/tasks/${queryParams.id}/add-users`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
  },
}
