export default [
  // *===============================================---*
  // *--------- CLIENTS --------------------------------*
  // *===============================================---*
  {
    path: '/clients/list',
    name: 'clients-list',
    component: () => import('@/views/apps/client/clients-list/ClientsList.vue'),
    meta: {
      action: 'view_all',
      resource: 'clients',
    },
  },
  {
    path: '/clients/view/:id',
    name: 'clients-view',
    component: () => import('@/views/apps/client/clients-view/ClientsView.vue'),
    meta: {
      action: 'view_all',
      resource: 'clients',
    },
  },
  {
    path: '/clients/edit/:id',
    name: 'clients-edit',
    component: () => import('@/views/apps/client/clients-edit/ClientsEdit.vue'),
    meta: {
      action: 'edit_all',
      resource: 'clients',
    },
  },

  // *===============================================---*
  // *--------- PROJECTS -------------------------------*
  // *===============================================---*
  {
    path: '/projects/list',
    name: 'projects-list',
    component: () => import('@/views/apps/project/projects-list/ProjectsList.vue'),
    meta: {
      action: 'view_own',
      resource: 'projects',
    },
  },
  {
    path: '/projects/view/:id',
    name: 'projects-view',
    component: () => import('@/views/apps/project/projects-view/ProjectsView.vue'),
    meta: {
      action: 'view_own',
      resource: 'projects',
    },
  },
  {
    path: '/projects/edit/:id',
    name: 'projects-edit',
    component: () => import('@/views/apps/project/projects-edit/ProjectsEdit.vue'),
    meta: {
      action: 'edit_own',
      resource: 'projects',
    },
  },
  {
    path: '/projects/new/',
    name: 'projects-new',
    component: () => import('@/views/apps/project/projects-edit/ProjectsEdit.vue'),
    meta: {
      action: 'create',
      resource: 'projects',
    },
  },

  // *===============================================---*
  // *--------- TASKS -------------------------------*
  // *===============================================---*
  {
    path: '/tasks/list',
    name: 'tasks-list',
    component: () => import('@/views/apps/task/tasks-list/TasksList.vue'),
    meta: {
      action: 'view_own',
      resource: 'tasks',
    },
  },

  // *===============================================---*
  // *--------- MAINTENANCES  ---------------------------*
  // *===============================================---*
  {
    path: '/maintenances/list',
    name: 'maintenances-list',
    component: () => import('@/views/apps/maintenance/maintenances-list/MaintenancesList.vue'),
    meta: {
      action: 'view_own',
      resource: 'maintenances',
    },
  },

  // *===============================================---*
  // *--------- MATERIALS  ---------------------------*
  // *===============================================---*
  {
    path: '/materials/list',
    name: 'materials-list',
    component: () => import('@/views/apps/material/materials-list/MaterialsList.vue'),
    meta: {
      action: 'view_all',
      resource: 'materials',
    },
  },

  // *===============================================---*
  // *--------- USERS --------------------------------*
  // *===============================================---*
  {
    path: '/confirm-user-account/:uuid',
    name: 'confirm-user-account',
    component: () => import('@/views/apps/user/users-confirm-account/UsersConfirmAccount.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      action: 'view_all',
      resource: 'users',
    },
  },
  {
    path: '/users/view/:uuid',
    name: 'users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      action: 'view_own',
      resource: 'users',
    },
  },

  // *===============================================---*
  // *--------- EVOStore -------------------------------*
  // *===============================================---*
  {
    path: '/evostore',
    name: 'evostore',
    component: () => import('@/views/apps/evostore/evostore-list/EvostoreList.vue'),
  },
]
