import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Retrieves all maintenances (paginated) for the registered user
    fetchMaintenances(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/maintenances`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchMaintenance(ctx, { projectId, id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${projectId}/maintenances/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMaintenance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenances`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    editMaintenance(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
          const currentSlug = localStorage.getItem('currentSlug')
          // const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
          axios
              .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenances/${queryParams.id}`, formData)
              .then(response => resolve(response))
              .catch(error => {
                  reject(error)
              })
      })
    },
    deleteMaintenance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          const currentSlug = localStorage.getItem('currentSlug')
          axios
              .delete(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenances/${queryParams.id}`)
              .then(response => resolve(response))
              .catch(error => {
                  reject(error)
              })
      })
    },
    updateMaintenanceTasksOrder(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/maintenances/${queryParams.id}/tasksorder`, formData)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    addMaintenanceUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenances/${queryParams.id}/add-users`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    deleteMaintenanceTask(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .delete(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenance/${queryParams.id}/tasks/${queryParams.task_id}`)
          .then(response => resolve(response))
          .catch(error => {
              reject(error)
          })
      })
    },
    updateMaintenanceTask(ctx, queryParams) {
        const formData = new FormData()
        // eslint-disable-next-line no-unused-expressions
        Object.entries(queryParams.task).forEach(([key, val]) => { val ? formData.append(key, val) : null })
        formData.append('_method', "PATCH")

        return new Promise((resolve, reject) => {
            const currentSlug = localStorage.getItem('currentSlug')
            axios
                .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenance/${queryParams.id}/tasks/${queryParams.task.id}`, formData)
                .then(response => resolve(response))
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteMaintenanceMaterial(ct, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .delete(`${process.env.VUE_APP_API_V}/${currentSlug}/materials-assignment/${queryParams.assigned_id}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    renewMaintenance(ctx, queryParams) {
        const formData = new FormData()
        // eslint-disable-next-line no-unused-expressions
        Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
        formData.append('_method', "PATCH")

        return new Promise((resolve, reject) => {
            const currentSlug = localStorage.getItem('currentSlug')
            // const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
            axios
                .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.project_id}/maintenances/${queryParams.id}/renew`, formData)
                .then(response => resolve(response))
                .catch(error => {
                    reject(error)
                })
        })
    },
  },
}
