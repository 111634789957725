import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/list`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchProject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addProject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    addProjectUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}/add-users`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    updateProject(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}`, formData, formHeaders)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchProjectUsers(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/users`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectTasks(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/tasks`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectMaintenances(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/maintenances`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectTags(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/tags`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectDocuments(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/documents`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectPhotos(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/images`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    uploadPhotos(ctx, queryParams) {
      const formData = new FormData()
      formData.append('id', queryParams.id)
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams.images).forEach(([key, val]) => { val ? formData.append(`images[${key}]`, val) : null })
      // formData.append('_method', "PATCH")
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}/images`, formData, formHeaders)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    deletePhoto(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}/images/${queryParams.idPhoto}/delete`, queryParams)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    uploadDocuments(ctx, queryParams) {
      const formData = new FormData()
      formData.append('id', queryParams.id)
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams.documents).forEach(([key, val]) => { val ? formData.append(`documents[${key}]`, val) : null })
      // formData.append('_method', "PATCH")
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}/documents`, formData, formHeaders)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${queryParams.id}/documents/${queryParams.documentId}/delete`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchProjectIssues(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/issues`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectResponsible(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/responsible`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchProjectActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${id}/activity`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkMaterial(ct, params) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${params.project_id}/material-assigned/${params.material_id}/status/${params.status}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    taskDone(ct, params) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/projects/${params.project_id}/maintenance-task/${params.task_id}/status/${params.status}`)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
