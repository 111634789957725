import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag"
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'
LogRocket.init('lsmgvv/evorgy')
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Crisp Chat
/*
import CrispChat from '@dansmaculotte/vue-crisp-chat'

Vue.use(CrispChat, {
  websiteId: process.env.VUE_APP_CRISP_WEBSITE_ID,
  disabled: true,
  hideOnLoad: true,
}) */

// GTM Google

// Debug

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY, loaderOptions: { autoHideBadge: true } })
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM_ID },
})

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_URL_KEY,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "t-evorgy.app", "evorgy.app", /^\//],
    }),
  ],
  trackComponents: true,
  tracesSampleRate: 1.0, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
})

// Axios Mock Adapter
// import '@/@fake-db/db'

if (process.env.VUE_APP_DEBUG) {
  /* const { RayPlugin } = require('vue-ray/vue2')
  Vue.use(RayPlugin, {
    interceptErrors: true,
    host: '127.0.0.1',
    // showComponentEvents: ['mounted'],
    nodeRaySettings: {
      interceptConsoleLog: false,
    },
  }) */
}

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
