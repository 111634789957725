import profile from './profileModel'
import general from './generalModel'
import user from './userModel'
import project from './projectModel'
import client from './clientModel'
import maintenance from './maintenanceModel'
import task from './taskModel'
import material from './materialModel'
import tenant from './tenantModel'

export default {
    namespaced: true,
    modules: {
        profile,
        general,
        user,
        project,
        client,
        maintenance,
        task,
        material,
        tenant,
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
}
