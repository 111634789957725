// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users/list`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchUser(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      const formData = new FormData()
      formData.append('_method', "DELETE")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${id}`, formData, formHeaders)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    addUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
        axios
            .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users`, queryParams, formHeaders)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    updateUser(ctx, queryParams) {
      const formData = new FormData()
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams).forEach(([key, val]) => { val ? formData.append(key, val) : null })
      formData.append('_method', "PATCH")

      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }
        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${queryParams.id}`, formData, formHeaders)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    setNewEmail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/user/${queryParams.id}/change-email/${queryParams.newEmail}`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/get-roles`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchUserProjects(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/user/${uuid}/projects`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchUserTasks(ctx, { id }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/user/${id}/tasks`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchUserMaintenances(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/user/${uuid}/maintenances`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchUserActivity(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        axios
            .get(`${process.env.VUE_APP_API_V}/${currentSlug}/user/${uuid}/activity`)
            .then(response => resolve(response))
            .catch(error => {
              reject(error)
            })
      })
    },
    fetchUserDocuments(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .get(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${uuid}/documents`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadDocuments(ctx, queryParams) {
      const formData = new FormData()
      formData.append('id', queryParams.id)
      // eslint-disable-next-line no-unused-expressions
      Object.entries(queryParams.documents).forEach(([key, val]) => { val ? formData.append(`documents[${key}]`, val) : null })
      // formData.append('_method', "PATCH")
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')
        const formHeaders = { headers: { "Content-Type": "multipart/form-data" } }

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${queryParams.uuid}/documents`, formData, formHeaders)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const currentSlug = localStorage.getItem('currentSlug')

        axios
          .post(`${process.env.VUE_APP_API_V}/${currentSlug}/users/${queryParams.uuid}/documents/${queryParams.documentId}/delete`, queryParams)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
